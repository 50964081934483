// @flow
import { Typography } from '@material-ui/core'
import CardActionArea from '@material-ui/core/CardActionArea'
import React, { FC, useState } from 'react'
import ReactGA from 'react-ga4'
import useStyles from 'src/components/organisms/AmbassadorVideo/index.styles'
import Modal from 'src/components/organisms/Modal'
import PlayIcon from 'src/images/icons/play.svg'

type AmbassadorVideoProps = {
  thumbnail: string
  title: string
  desc: string
  url: string
}

export const AmbassadorVideo: FC<AmbassadorVideoProps> = ({
  title,
  desc,
  thumbnail,
  url
}) => {
  const [videoOpen, setVideoOpen] = useState(false)
  const styles = useStyles({
    thumbnail
  })
  return (
    <>
      <div className={styles.ambassadorVideo}>
        <CardActionArea
          className={styles.videoThumbnail}
          onClick={() => {
            ReactGA.event({
              category: "watch_video",
              action: "watch_video",
              label: title,
            })
            setVideoOpen(true)
          }}
        >
          <img src={PlayIcon} alt="playicon" />
          {/* <PlayIcon /> */}
        </CardActionArea>
        <div className={styles.videoDesc}>
          <Typography variant="h5" className={styles.videoTitle}>
            {title}
          </Typography>
          <Typography variant="body1" className={styles.videoBody}>
            {desc}
          </Typography>
        </div>
      </div>
      <Modal
        withCloseButton
        isOpen={videoOpen}
        onClose={() => {
          ReactGA.event({
            category: "close_after_watched_video",
            action: "close_after_watched_video",
            label: title,
          })
          setVideoOpen(false)
        }}
      >
        <iframe
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="ambassador video"
          className={styles.videoIframe}
          src={url}
        />
      </Modal>
    </>
  )
}
