// @flow
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import useStyles from 'src/components/molecules/TourEventCard/index.styles'
import './content.css'

type TourEventCardProps = {
  coverImage: string
  content?: string
  title: string
  isVisible?: boolean
}

const TourEventCard: FC<TourEventCardProps> = ({
  coverImage,
  content,
  title,
  isVisible
}) => {
  const styles = useStyles()

  return isVisible ? (
    <Card className={styles.card}>
      {/* the ts-ignore is here to force the alt onto the CardMedia */}
      {/* @ts-ignore */}
      <CardMedia
        className={styles.cardMedia}
        image={coverImage}
        title={''}
        alt=""
      />

      <CardContent className={styles.cardContent}>
        <Typography
          className={styles.cardTitle}
          color="inherit"
          variant="h6"
          component="h1"
        >
          {title}
        </Typography>
        <div
          className={styles.cardBody}
          dangerouslySetInnerHTML={{ __html: content || '' }}
        ></div>
      </CardContent>
    </Card>
  ) : null
}

export default TourEventCard